//---Thread color code
// {
//   "DTL520": "#9e988b",
//   "DTL5205": "#988c77",
//   "DTL521": "#997f49",
//   "DTL522": "#996a35",
//   "DTL523": "#95602b",
//   "DTL524": "#884720",
//   "DTL525": "#8b3f1b",
//   "DTL783": "#4d2f21",
//   "DTL784": "#492a20",
//   "DTL785": "#42261e",
//   "DTL786": "#331f1e",
//   "DTL370": "#405a66",
//   "DTL371": "#164665",
//   "DTL372": "#103252",
//   "DTL373": "#12294c",
//   "DTL721": "#5f4b3c",
//   "DTL722": "#3e2c22",
//   "DTL723": "#463123",
//   "DTL117": "#7d4a4e",
//   "DTL119": "#40161f",
//   "DTL121": "#2a181a",
//   "DTL100": "#a89c99",
//   "DTL101": "#b29ba2",
//   "DTL102": "#ae8e98",
//   "DTL103": "#ac7884",
//   "DTL104": "#a36270",
//   "DTL105": "#a14e5b",
//   "DTL106": "#8b3845",
//   "DTL260": "#879792",
//   "DTL261": "#7b9784",
//   "DTL262": "#7b977e",
//   "DTL263": "#4f7a57",
//   "DTL264": "#1f5c3e",
//   "DTL265": "#1e4434",
//   "DTL200": "#1c3b2c",
//   "DTL361": "#7e8c98",
//   "DTL362": "#5b7287",
//   "DTL363": "#576c81",
//   "DTL364": "#325870",
//   "DTL365": "#1f334e",
//   "DTL366": "#0f1941",
//   "DTL367": "#1c2141",
//   "DTL140": "#c59e9e",
//   "DTL141": "#c1989d",
//   "DTL142": "#bc7f7a",
//   "DTL143": "#ae6b6d",
//   "DTL144": "#8a423f",
//   "DTL145": "#802723",
//   "DTL810": "#a49892",
//   "DTL811": "#98867c",
//   "DTL813": "#807269",
//   "DTL814": "#6d5b55",
//   "DTL815": "#5e4e47"
// }

/*
Embroidery fabric color code */
const fabricColor = [
  { colorHex: '#5f0008', name: 'JM-201', row: 1 },
  { colorHex: '#490005', name: 'JM-202', row: 2 },
  { colorHex: '#1e0306', name: 'JM-203', row: 3 },
  { colorHex: '#456d39', name: 'JM-204', row: 4 },
  { colorHex: '#42783b', name: 'JM-205', row: 5 },
  { colorHex: '#154c29', name: 'JM-206', row: 6 },
  { colorHex: '#103623', name: 'JM-207', row: 7 },
  { colorHex: '#140e08', name: 'JM-208', row: 8 },
  { colorHex: '#16072b', name: 'JM-209', row: 9 },
  { colorHex: '#a6a074', name: 'JM-210', row: 10 },
  { colorHex: '#ac9b49', name: 'JM-211', row: 11 },
  { colorHex: '#ac8635', name: 'JM-212', row: 12 },
  { colorHex: '#64554c', name: 'JM-213', row: 13 },
  { colorHex: '#9e9bab', name: 'JM-214', row: 14 },
  { colorHex: '#9b8b7f', name: 'JM-215', row: 15 },
  { colorHex: '#725b50', name: 'JM-216', row: 16 },
  { colorHex: '#65554d', name: 'JM-217', row: 17 },
  { colorHex: '#1a1211', name: 'JM-218', row: 18 },
  { colorHex: '#84949c', name: 'JM-219', row: 19 },
  { colorHex: '#719297', name: 'JM-220', row: 20 },
  { colorHex: '#638b9e', name: 'JM-221', row: 21 },
  { colorHex: '#576b8b', name: 'JM-222', row: 22 },
  { colorHex: '#3f5d88', name: 'JM-223', row: 23 },
  { colorHex: '#b49364', name: 'JM-224', row: 24 },
  { colorHex: '#b1712e', name: 'JM-225', row: 25 },
  { colorHex: '#a96137', name: 'JM-226', row: 26 },
  { colorHex: '#3f8187', name: 'JM-227', row: 27 },
  { colorHex: '#494a43', name: 'JM-228', row: 28 },
  { colorHex: '#442444', name: 'JM-285', row: 29 },
  { colorHex: '#99244f', name: 'JM-286', row: 30 },
  { colorHex: '#7d182b', name: 'JM-287', row: 31 },
  { colorHex: '#748ba2', name: 'JM-288', row: 32 },
  { colorHex: '#11172a', name: 'JM-289', row: 33 },
  { colorHex: '#2f517c', name: 'JM-290', row: 34 },
  { colorHex: '#1e1d23', name: 'JM-291', row: 35 },
  { colorHex: '#2f4164', name: 'JM-292', row: 36 },
  { colorHex: '#2e3b30', name: 'JM-293', row: 37 },
  { colorHex: '#7b1327', name: 'JM-294', row: 38 },
  { colorHex: '#4e4a4d', name: 'JM-295', row: 39 },
  { colorHex: '#2d2d32', name: 'JM-296', row: 40 },
  { colorHex: '#697840', name: 'JM-297', row: 41 },
  { colorHex: '#9e7a5f', name: 'JM-298', row: 42 },
  { colorHex: '#b8b7bb', name: 'JM-299', row: 43 },
  { colorHex: '#b9b7bc', name: 'JM-300', row: 44 },
  { colorHex: '#b6b5b9', name: 'JM-301', row: 45 },
  { colorHex: '#b3b2b5', name: 'JM-302', row: 46 },
  { colorHex: '#b1afb3', name: 'JM-303', row: 47 },
  { colorHex: '#b2b0b4', name: 'JM-304', row: 48 },
  { colorHex: '#b1afb4', name: 'JM-305', row: 49 },
  { colorHex: '#b8b7bc', name: 'JM-306', row: 50 },
  { colorHex: '#b8b7bb', name: 'JM-307', row: 51 },
  { colorHex: '#b6b5b9', name: 'JM-308', row: 52 },
  { colorHex: '#b4b2b7', name: 'JM-309', row: 53 },
  { colorHex: '#b1afb4', name: 'JM-310', row: 54 },
  { colorHex: '#aeacb1', name: 'JM-311', row: 55 },
  { colorHex: '#acaab0', name: 'JM-312', row: 56 },
];

//WOVEN THREAD COLOR

const wovenThread = [
  { colorHex: '#F5F3D6', name: 'GS2100' },
  { colorHex: '#F5ECC8', name: 'GS2101' },
  { colorHex: '#F6E7B7', name: 'GS2102' },
  { colorHex: '#F5E8C0', name: 'GS2103' },
  { colorHex: '#E6D1B9', name: 'GS2104' },
  { colorHex: '#E8D3B5', name: 'GS2105' },
  { colorHex: '#E3C88F', name: 'GS2106' },
  { colorHex: '#D4B176', name: 'GS2107' },
  { colorHex: '#D5AC62', name: 'GS2108' },
  { colorHex: '#D3B17E', name: 'GS2109' },
  { colorHex: '#EFF7F8', name: 'GS2110' },
  { colorHex: '#F2F5EF', name: 'GS2111' },
  { colorHex: '#EFF3EB', name: 'GS2112' },
  { colorHex: '#F3F3D3', name: 'GS2113' },
  { colorHex: '#F0E3B7', name: 'GS2114' },
  { colorHex: '#F3EAAC', name: 'GS2115' },
  { colorHex: '#F2DEA0', name: 'GS2116' },
  { colorHex: '#F9E594', name: 'GS2117' },
  { colorHex: '#F2DB90', name: 'GS2118' },
  { colorHex: '#F8CF54', name: 'GS2119' },
  { colorHex: '#F8FCD4', name: 'GS2300' },
  { colorHex: '#F9FA8D', name: 'GS2301' },
  { colorHex: '#FAFA6B', name: 'GS2302' },
  { colorHex: '#F5F38B', name: 'GS2303' },
  { colorHex: '#FAEC20', name: 'GS2304' },
  { colorHex: '#FBF600', name: 'GS2305' },
  { colorHex: '#F8E900', name: 'GS2306' },
  { colorHex: '#FAE001', name: 'GS2307' },
  { colorHex: '#FCE201', name: 'GS2308' },
  { colorHex: '#FCE205', name: 'GS2309' },
  { colorHex: '#F7DF75', name: 'GS2310' },
  { colorHex: '#F2C84F', name: 'GS2311' },
  { colorHex: '#F8C83A', name: 'GS2312' },
  { colorHex: '#F8C201', name: 'GS2313' },
  { colorHex: '#FAAD01', name: 'GS2314' },
  { colorHex: '#F8AF02', name: 'GS2315' },
  { colorHex: '#F9AE18', name: 'GS2316' },
  { colorHex: '#F9A201', name: 'GS2317' },
  { colorHex: '#F78101', name: 'GS2318' },
  { colorHex: '#F58500', name: 'GS2319' },
  { colorHex: '#F9AB59', name: 'GS2320' },
  { colorHex: '#F67409', name: 'GS2321' },
  { colorHex: '#F57706', name: 'GS2322' },
  { colorHex: '#EE7517', name: 'GS2323' },
  { colorHex: '#F86703', name: 'GS2324' },
  { colorHex: '#F45503', name: 'GS2325' },
  { colorHex: '#E73101', name: 'GS2326' },
  { colorHex: '#E62B03', name: 'GS2327' },
  { colorHex: '#D10D02', name: 'GS2328' },
  { colorHex: '#B83B06', name: 'GS2329' },
  { colorHex: '#EB321B', name: 'GS2400' },
  { colorHex: '#CF0908', name: 'GS2401' },
  { colorHex: '#D90A1D', name: 'GS2402' },
  { colorHex: '#AB0403', name: 'GS2403' },
  { colorHex: '#B80707', name: 'GS2404' },
  { colorHex: '#98040B', name: 'GS2405' },
  { colorHex: '#880304', name: 'GS2406' },
  { colorHex: '#9E0C1A', name: 'GS2407' },
  { colorHex: '#8A0824', name: 'GS2408' },
  { colorHex: '#7D0826', name: 'GS2409' },
  { colorHex: '#F37B7E', name: 'GS2410' },
  { colorHex: '#F15F68', name: 'GS2411' },
  { colorHex: '#B91C27', name: 'GS2412' },
  { colorHex: '#CF0405', name: 'GS2413' },
  { colorHex: '#BF0200', name: 'GS2414' },
  { colorHex: '#A40B09', name: 'GS2415' },
  { colorHex: '#AA0206', name: 'GS2416' },
  { colorHex: '#B10411', name: 'GS2417' },
  { colorHex: '#7F0F28', name: 'GS2418' },
  { colorHex: '#8B0E30', name: 'GS2419' },
  { colorHex: '#F8DADF', name: 'GS2420' },
  { colorHex: '#F9BFC1', name: 'GS2421' },
  { colorHex: '#F492AE', name: 'GS2422' },
  { colorHex: '#F98EA8', name: 'GS2423' },
  { colorHex: '#E0707D', name: 'GS2424' },
  { colorHex: '#DD3860', name: 'GS2425' },
  { colorHex: '#D20B50', name: 'GS2426' },
  { colorHex: '#CC0F55', name: 'GS2427' },
  { colorHex: '#C4064E', name: 'GS2428' },
  { colorHex: '#C60329', name: 'GS2429' },
  { colorHex: '#F6E0F0', name: 'GS2430' },
  { colorHex: '#F9AED7', name: 'GS2431' },
  { colorHex: '#F5A9D3', name: 'GS2432' },
  { colorHex: '#FA6895', name: 'GS2433' },
  { colorHex: '#F41467', name: 'GS2434' },
  { colorHex: '#F03580', name: 'GS2435' },
  { colorHex: '#F1076D', name: 'GS2436' },
  { colorHex: '#D10968', name: 'GS2437' },
  { colorHex: '#A30257', name: 'GS2438' },
  { colorHex: '#C11877', name: 'GS2439' },
  { colorHex: '#F8DCE8', name: 'GS2440' },
  { colorHex: '#F9BFD9', name: 'GS2441' },
  { colorHex: '#F7A1CC', name: 'GS2442' },
  { colorHex: '#F38EB6', name: 'GS2443' },
  { colorHex: '#C6547F', name: 'GS2444' },
  { colorHex: '#85060D', name: 'GS2445' },
  { colorHex: '#6F0216', name: 'GS2446' },
  { colorHex: '#75041F', name: 'GS2447' },
  { colorHex: '#720916', name: 'GS2448' },
  { colorHex: '#69142B', name: 'GS2449' },
  { colorHex: '#F5E3F3', name: 'GS2450' },
  { colorHex: '#F5E3F3', name: 'GS2451' },
  { colorHex: '#C08FAB', name: 'GS2452' },
  { colorHex: '#CE81AD', name: 'GS2453' },
  { colorHex: '#D18AB3', name: 'GS2454' },
  { colorHex: '#95386F', name: 'GS2455' },
  { colorHex: '#7B3771', name: 'GS2456' },
  { colorHex: '#7E3374', name: 'GS2457' },
  { colorHex: '#7D3163', name: 'GS2458' },
  { colorHex: '#CE81AD', name: 'GS2459' },
  { colorHex: '#F0D1F0', name: 'GS2500' },
  { colorHex: '#E6B2DB', name: 'GS2501' },
  { colorHex: '#DC9EC5', name: 'GS2502' },
  { colorHex: '#CB71B9', name: 'GS2503' },
  { colorHex: '#A854AA', name: 'GS2504' },
  { colorHex: '#9A2D99', name: 'GS2505' },
  { colorHex: '#A81C8B', name: 'GS2506' },
  { colorHex: '#930C72', name: 'GS2507' },
  { colorHex: '#AA0D71', name: 'GS2508' },
  { colorHex: '#981458', name: 'GS2509' },
  { colorHex: '#EFE8FC', name: 'GS2510' },
  { colorHex: '#C5B7EA', name: 'GS2511' },
  { colorHex: '#B293E2', name: 'GS2512' },
  { colorHex: '#9B76DE', name: 'GS2513' },
  { colorHex: '#BB6CBF', name: 'GS2514' },
  { colorHex: '#B554BF', name: 'GS2515' },
  { colorHex: '#794D8B', name: 'GS2516' },
  { colorHex: '#6B3A74', name: 'GS2517' },
  { colorHex: '#6B3A74', name: 'GS2518' },
  { colorHex: '#5F073A', name: 'GS2519' },
  { colorHex: '#EDEDEF', name: 'GS2520' },
  { colorHex: '#CDCFDC', name: 'GS2521' },
  { colorHex: '#BBB6C4', name: 'GS2522' },
  { colorHex: '#A8A3C1', name: 'GS2523' },
  { colorHex: '#847F9D', name: 'GS2524' },
  { colorHex: '#5E587E', name: 'GS2525' },
  { colorHex: '#4A3E64', name: 'GS2526' },
  { colorHex: '#43315A', name: 'GS2527' },
  { colorHex: '#262854', name: 'GS2528' },
  { colorHex: '#2E305E', name: 'GS2529' },
  { colorHex: '#DCE3FA', name: 'GS2530' },
  { colorHex: '#B2C3F0', name: 'GS2531' },
  { colorHex: '#81A7EF', name: 'GS2532' },
  { colorHex: '#7070BD', name: 'GS2533' },
  { colorHex: '#5E7AD4', name: 'GS2534' },
  { colorHex: '#7A76CC', name: 'GS2535' },
  { colorHex: '#662CA6', name: 'GS2536' },
  { colorHex: '#65168C', name: 'GS2537' },
  { colorHex: '#732595', name: 'GS2538' },
  { colorHex: '#590760', name: 'GS2539' },
  { colorHex: '#AEC8DD', name: 'GS2600' },
  { colorHex: '#98C2E0', name: 'GS2601' },
  { colorHex: '#2D87CE', name: 'GS2603' },
  { colorHex: '#033694', name: 'GS2604' },
  { colorHex: '#04308E', name: 'GS2605' },
  { colorHex: '#05287C', name: 'GS2606' },
  { colorHex: '#001C83', name: 'GS2607' },
  { colorHex: '#011257', name: 'GS2608' },
  { colorHex: '#010F45', name: 'GS2609' },
  { colorHex: '#7BBBEE', name: 'GS2610' },
  { colorHex: '#9ABEF6', name: 'GS2611' },
  { colorHex: '#6592DF', name: 'GS2612' },
  { colorHex: '#1B4DA4', name: 'GS2613' },
  { colorHex: '#113F8F', name: 'GS2614' },
  { colorHex: '#154098', name: 'GS2615' },
  { colorHex: '#13347C', name: 'GS2616' },
  { colorHex: '#0C2157', name: 'GS2617' },
  { colorHex: '#0F1D48', name: 'GS2618' },
  { colorHex: '#0E1944', name: 'GS2619' },
  { colorHex: '#353C6B', name: 'GS2620' },
  { colorHex: '#2D417A', name: 'GS2621' },
  { colorHex: '#405D92', name: 'GS2622' },
  { colorHex: '#253A6F', name: 'GS2623' },
  { colorHex: '#465880', name: 'GS2624' },
  { colorHex: '#31415B', name: 'GS2625' },
  { colorHex: '#1C2A4B', name: 'GS2626' },
  { colorHex: '#1E2542', name: 'GS2628' },
  { colorHex: '#2D3749', name: 'GS2629' },
  { colorHex: '#CDEFF2', name: 'GS2630' },
  { colorHex: '#C6E4E6', name: 'GS2631' },
  { colorHex: '#C5E5EE', name: 'GS2632' },
  { colorHex: '#B2D4E6', name: 'GS2633' },
  { colorHex: '#B4CBD9', name: 'GS2634' },
  { colorHex: '#B6D1DF', name: 'GS2635' },
  { colorHex: '#97BBD5', name: 'GS2636' },
  { colorHex: '#5B8FBF', name: 'GS2637' },
  { colorHex: '#3E7CB5', name: 'GS2638' },
  { colorHex: '#4A78AA', name: 'GS2639' },
  { colorHex: '#CEDDDD', name: 'GS2640' },
  { colorHex: '#9DDDEF', name: 'GS2641' },
  { colorHex: '#44A6DA', name: 'GS2642' },
  { colorHex: '#3D8AB7', name: 'GS2643' },
  { colorHex: '#1E6D9C', name: 'GS2644' },
  { colorHex: '#17528A', name: 'GS2645' },
  { colorHex: '#0B2B5B', name: 'GS2646' },
  { colorHex: '#0B1D44', name: 'GS2647' },
  { colorHex: '#0B1B3D', name: 'GS2648' },
  { colorHex: '#171D2F', name: 'GS2649' },
  { colorHex: '#3FD2F4', name: 'GS2650' },
  { colorHex: '#44B7D0', name: 'GS2651' },
  { colorHex: '#1BC2ED', name: 'GS2652' },
  { colorHex: '#06A2E5', name: 'GS2653' },
  { colorHex: '#0795D4', name: 'GS2654' },
  { colorHex: '#0D91E3', name: 'GS2655' },
  { colorHex: '#0385DD', name: 'GS2656' },
  { colorHex: '#0F7EDD', name: 'GS2657' },
  { colorHex: '#0D4C9C', name: 'GS2658' },
  { colorHex: '#0C4C89', name: 'GS2659' },
  { colorHex: '#C9E5F2', name: 'GS2660' },
  { colorHex: '#A3C3DC', name: 'GS2661' },
  { colorHex: '#6ADFF3', name: 'GS2662' },
  { colorHex: '#2DC4DD', name: 'GS2663' },
  { colorHex: '#0B95BC', name: 'GS2664' },
  { colorHex: '#24749D', name: 'GS2665' },
  { colorHex: '#2A7CA7', name: 'GS2666' },
  { colorHex: '#1C6192', name: 'GS2667' },
  { colorHex: '#085486', name: 'GS2668' },
  { colorHex: '#0E6398', name: 'GS2669' },
  { colorHex: '#D9F9FB', name: 'GS2700' },
  { colorHex: '#A1F3F4', name: 'GS2701' },
  { colorHex: '#8EF4F1', name: 'GS2702' },
  { colorHex: '#7CEAD4', name: 'GS2703' },
  { colorHex: '#1FB8B4', name: 'GS2704' },
  { colorHex: '#0CABB3', name: 'GS2705' },
  { colorHex: '#089FAD', name: 'GS2706' },
  { colorHex: '#0A9395', name: 'GS2707' },
  { colorHex: '#09958F', name: 'GS2708' },
  { colorHex: '#02867B', name: 'GS2709' },
  { colorHex: '#EAFDF9', name: 'GS2710' },
  { colorHex: '#BFEDD3', name: 'GS2711' },
  { colorHex: '#BAE3BC', name: 'GS2712' },
  { colorHex: '#B3E2AC', name: 'GS2713' },
  { colorHex: '#D4FCC2', name: 'GS2714' },
  { colorHex: '#86B9A8', name: 'GS2715' },
  { colorHex: '#6A917E', name: 'GS2716' },
  { colorHex: '#46857D', name: 'GS2717' },
  { colorHex: '#51918F', name: 'GS2718' },
  { colorHex: '#3B6562', name: 'GS2719' },
  { colorHex: '#74E0BC', name: 'GS2720' },
  { colorHex: '#12AA83', name: 'GS2721' },
  { colorHex: '#0BA174', name: 'GS2722' },
  { colorHex: '#46857D', name: 'GS2723' },
  { colorHex: '#128041', name: 'GS2724' },
  { colorHex: '#046537', name: 'GS2725' },
  { colorHex: '#09693E', name: 'GS2726' },
  { colorHex: '#046255', name: 'GS2727' },
  { colorHex: '#066363', name: 'GS2728' },
  { colorHex: '#0E5351', name: 'GS2729' },
  { colorHex: '#DDFC74', name: 'GS2730' },
  { colorHex: '#74D755', name: 'GS2731' },
  { colorHex: '#47B925', name: 'GS2732' },
  { colorHex: '#0F8810', name: 'GS2733' },
  { colorHex: '#3AB45F', name: 'GS2734' },
  { colorHex: '#0A8431', name: 'GS2735' },
  { colorHex: '#06823D', name: 'GS2736' },
  { colorHex: '#05723C', name: 'GS2737' },
  { colorHex: '#104321', name: 'GS2738' },
  { colorHex: '#0C3C29', name: 'GS2739' },
  { colorHex: '#A1D8AE', name: 'GS2740' },
  { colorHex: '#7C9B79', name: 'GS2741' },
  { colorHex: '#596139', name: 'GS2742' },
  { colorHex: '#05723C', name: 'GS2743' },
  { colorHex: '#45411B', name: 'GS2744' },
  { colorHex: '#4E5B2D', name: 'GS2745' },
  { colorHex: '#4E472D', name: 'GS2746' },
  { colorHex: '#4B624E', name: 'GS2747' },
  { colorHex: '#0A2E1D', name: 'GS2748' },
  { colorHex: '#05723C', name: 'GS2740' },
  { colorHex: '#B09A71', name: 'GS2750' },
  { colorHex: '#B9A570', name: 'GS2751' },
  { colorHex: '#827F5E', name: 'GS2752' },
  { colorHex: '#879154', name: 'GS2753' },
  { colorHex: '#67950F', name: 'GS2754' },
  { colorHex: '#8CA40F', name: 'GS2755' },
  { colorHex: '#828B15', name: 'GS2756' },
  { colorHex: '#6B700B', name: 'GS2757' },
  { colorHex: '#726B40', name: 'GS2758' },
  { colorHex: '#545023', name: 'GS2759' },
  { colorHex: '#CFB076', name: 'GS2200' },
  { colorHex: '#D1AD79', name: 'GS2201' },
  { colorHex: '#D4B586', name: 'GS2202' },
  { colorHex: '#6B700B', name: 'GS2203' },
  { colorHex: '#BE9871', name: 'GS2204' },
  { colorHex: '#B89870', name: 'GS2205' },
  { colorHex: '#B1987F', name: 'GS2206' },
  { colorHex: '#B89872', name: 'GS2207' },
  { colorHex: '#A98B73', name: 'GS2208' },
  { colorHex: '#9C8776', name: 'GS2209' },
  { colorHex: '#E1B170', name: 'GS2210' },
  { colorHex: '#EBB25D', name: 'GS2211' },
  { colorHex: '#D9B45C', name: 'GS2212' },
  { colorHex: '#E7BE4B', name: 'GS2213' },
  { colorHex: '#EDB02E', name: 'GS2214' },
  { colorHex: '#D89B05', name: 'GS2215' },
  { colorHex: '#C39103', name: 'GS2216' },
  { colorHex: '#BD8F01', name: 'GS2217' },
  { colorHex: '#AD8103', name: 'GS2218' },
  { colorHex: '#B07203', name: 'GS2219' },
  { colorHex: '#C8B385', name: 'GS2220' },
  { colorHex: '#9F6C28', name: 'GS2221' },
  { colorHex: '#906B35', name: 'GS2222' },
  { colorHex: '#8E680E', name: 'GS2223' },
  { colorHex: '#A96600', name: 'GS2224' },
  { colorHex: '#9B5D02', name: 'GS2225' },
  { colorHex: '#A05E0C', name: 'GS2226' },
  { colorHex: '#A55C28', name: 'GS2227' },
  { colorHex: '#8C410A', name: 'GS2228' },
  { colorHex: '#7F4E10', name: 'GS2229' },
  { colorHex: '#A96600', name: 'GS2230' },
  { colorHex: '#FBE0B9', name: 'GS2231' },
  { colorHex: '#D8B690', name: 'GS2232' },
  { colorHex: '#BD937D', name: 'GS2233' },
  { colorHex: '#D8A173', name: 'GS2234' },
  { colorHex: '#E9AA5E', name: 'GS2235' },
  { colorHex: '#EA791B', name: 'GS2236' },
  { colorHex: '#BB4401', name: 'GS2237' },
  { colorHex: '#AE490A', name: 'GS2238' },
  { colorHex: '#A54006', name: 'GS2239' },
  { colorHex: '#B87D69', name: 'GS2240' },
  { colorHex: '#A97762', name: 'GS2241' },
  { colorHex: '#77493A', name: 'GS2242' },
  { colorHex: '#744525', name: 'GS2243' },
  { colorHex: '#623B0D', name: 'GS2244' },
  { colorHex: '#6C0601', name: 'GS2245' },
  { colorHex: '#602A22', name: 'GS2246' },
  { colorHex: '#542A21', name: 'GS2247' },
  { colorHex: '#6D302E', name: 'GS2248' },
  { colorHex: '#901A1B', name: 'GS2249' },
  { colorHex: '#AA9886', name: 'GS2250' },
  { colorHex: '#6C0601', name: 'GS2251' },
  { colorHex: '#908460', name: 'GS2252' },
  { colorHex: '#6C6240', name: 'GS2253' },
  { colorHex: '#7F7347', name: 'GS2254' },
  { colorHex: '#684002', name: 'GS2255' },
  { colorHex: '#653E11', name: 'GS2256' },
  { colorHex: '#502609', name: 'GS2257' },
  { colorHex: '#4D2520', name: 'GS2258' },
  { colorHex: '#462314', name: 'GS2259' },
  { colorHex: '#FFFFFF', name: 'GS2800' },
  { colorHex: '#E1E4EE', name: 'GS2801' },
  { colorHex: '#E3E7F0', name: 'GS2802' },
  { colorHex: '#502609', name: 'GS2803' },
  { colorHex: '#4D2520', name: 'GS2804' },
  { colorHex: '#CACFDA', name: 'GS2805' },
  { colorHex: '#C2C1C9', name: 'GS2806' },
  { colorHex: '#BCC6CB', name: 'GS2807' },
  { colorHex: '#93A3A3', name: 'GS2808' },
  { colorHex: '#8E9996', name: 'GS2809' },
  { colorHex: '#E3E1E2', name: 'GS2810' },
  { colorHex: '#E0E4E3', name: 'GS2811' },
  { colorHex: '#D0C8CB', name: 'GS2812' },
  { colorHex: '#8F939F', name: 'GS2814' },
  { colorHex: '#9C98A6', name: 'GS2815' },
  { colorHex: '#92959E', name: 'GS2816' },
  { colorHex: '#8F95A3', name: 'GS2817' },
  { colorHex: '#7A8293', name: 'GS2818' },
  { colorHex: '#7F8195', name: 'GS2819' },
  { colorHex: '#8F939F', name: 'GS2820' },
  { colorHex: '#AEAAAB', name: 'GS2821' },
  { colorHex: '#8A8589', name: 'GS2822' },
  { colorHex: '#8E8A8B', name: 'GS2823' },
  { colorHex: '#878781', name: 'GS2824' },
  { colorHex: '#7F8195', name: 'GS2825' },
  { colorHex: '#586877', name: 'GS2826' },
  { colorHex: '#738DA6', name: 'GS2827' },
  { colorHex: '#5D7091', name: 'GS2828' },
  { colorHex: '#8E8A8B', name: 'GS2829' },
  { colorHex: '#F1EAE0', name: 'GS2830' },
  { colorHex: '#E7E0CE', name: 'GS2831' },
  { colorHex: '#D2C5B5', name: 'GS2832' },
  { colorHex: '#CCC2BA', name: 'GS2833' },
  { colorHex: '#A9A69D', name: 'GS2834' },
  { colorHex: '#B2AEA9', name: 'GS2835' },
  { colorHex: '#97989C', name: 'GS2836' },
  { colorHex: '#9A9FA3', name: 'GS2837' },
  { colorHex: '#989D99', name: 'GS2838' },
  { colorHex: '#CCC2BA', name: 'GS2839' },
  { colorHex: '#F7F7F3', name: 'GS2840' },
  { colorHex: '#F9FBFA', name: 'GS2841' },
  { colorHex: '#E1DDDE', name: 'GS2842' },
  { colorHex: '#D8CFCD', name: 'GS2843' },
  { colorHex: '#CDC6C0', name: 'GS2844' },
  { colorHex: '#A49E8E', name: 'GS2845' },
  { colorHex: '#908476', name: 'GS2846' },
  { colorHex: '#8E8173', name: 'GS2846' },
  { colorHex: '#767369', name: 'GS2847' },
  { colorHex: '#706B68', name: 'GS2848' },
  { colorHex: '#6B6662', name: 'GS2849' },
  { colorHex: '#EAF6F6', name: 'GS2850' },
  { colorHex: '#C7C6C0', name: 'GS2851' },
  { colorHex: '#E8E1CF', name: 'GS2852' },
  { colorHex: '#D1CBB5', name: 'GS2853' },
  { colorHex: '#CBC4B1', name: 'GS2854' },
  { colorHex: '#C5BA9E', name: 'GS2855' },
  { colorHex: '#A7A495', name: 'GS2856' },
  { colorHex: '#C7C6C0', name: 'GS2857' },
  { colorHex: '#636969', name: 'GS2858' },
  { colorHex: '#828E88', name: 'GS2859' },
  { colorHex: '#7E8680', name: 'GS2860' },
  { colorHex: '#5E635F', name: 'GS2861' },
  { colorHex: '#414241', name: 'GS2862' },
  { colorHex: '#3A3836', name: 'GS2863' },
  { colorHex: '#3F413E', name: 'GS2864' },
  { colorHex: '#3A3D3C', name: 'GS2865' },
  { colorHex: '#3F4C41', name: 'GS2866' },
  { colorHex: '#465A5B', name: 'GS2867' },
  { colorHex: '#3A4A4D', name: 'GS2868' },
  { colorHex: '#414848', name: 'GS2869' },
  { colorHex: '#C8D6E5', name: 'GS2870' },
  { colorHex: '#718E9B', name: 'GS2871' },
  { colorHex: '#354C5C', name: 'GS2872' },
  { colorHex: '#3A505F', name: 'GS2873' },
  { colorHex: '#284555', name: 'GS2030' },
  { colorHex: '#EEF3F8', name: 'GS2031' },
  { colorHex: '#21100F', name: 'GS2052' },
  { colorHex: '#FDE683', name: 'GS2900' },
  { colorHex: '#FBD742', name: 'GS2901' },
  { colorHex: '#F8A255', name: 'GS2902' },
  { colorHex: '#FA9D3B', name: 'GS2903' },
  { colorHex: '#F66804', name: 'GS2904' },
  { colorHex: '#F55625', name: 'GS2905' },
  { colorHex: '#F74B2D', name: 'GS2906' },
  { colorHex: '#F76972', name: 'GS2907' },
  { colorHex: '#F63776', name: 'GS2908' },
  { colorHex: '#EE1D5D', name: 'GS2909' },
  { colorHex: '#FADD00', name: 'GS2910' },
  { colorHex: '#FBE002', name: 'GS2911' },
  { colorHex: '#FBD600', name: 'GS2912' },
  { colorHex: '#FABD02', name: 'GS2913' },
  { colorHex: '#F9FD26', name: 'GS2914' },
  { colorHex: '#F7FD62', name: 'GS2915' },
  { colorHex: '#F5FDBE', name: 'GS2916' },
  { colorHex: '#E7FD8E', name: 'GS2917' },
  { colorHex: '#BFFC79', name: 'GS2918' },
  { colorHex: '#6EEA63', name: 'GS2919' },
  { colorHex: '#F7E6DE', name: 'GS2340' },
  { colorHex: '#FAD8BC', name: 'GS2341' },
  { colorHex: '#F8DFC4', name: 'GS2342' },
  { colorHex: '#FBE197', name: 'GS2343' },
  { colorHex: '#FCDFA6', name: 'GS2344' },
  { colorHex: '#F5E25D', name: 'GS2345' },
  { colorHex: '#F7D00B', name: 'GS2346' },
  { colorHex: '#D5A600', name: 'GS2347' },
  { colorHex: '#D08104', name: 'GS2348' },
  { colorHex: '#FBE6E0', name: 'GS2460' },
  { colorHex: '#E35394', name: 'GS2461' },
  { colorHex: '#A00841', name: 'GS2462' },
  { colorHex: '#ABACDB', name: 'GS2540' },
  { colorHex: '#8390DC', name: 'GS2541' },
  { colorHex: '#5B2D78', name: 'GS2542' },
  { colorHex: '#5C1D7F', name: 'GS2543' },
  { colorHex: '#73279E', name: 'GS2544' },
  { colorHex: '#6C657E', name: 'GS2545' },
  { colorHex: '#7491B9', name: 'GS2670' },
  { colorHex: '#424C7F', name: 'GS2671' },
  { colorHex: '#523B7F', name: 'GS2672' },
  { colorHex: '#E8F9FD', name: 'GS2760' },
  { colorHex: '#9ADDDC', name: 'GS2761' },
  { colorHex: '#6F9DB7', name: 'GS2762' },
  { colorHex: '#1A495C', name: 'GS2763' },
  { colorHex: '#4D2A0E', name: 'GS2260' },
  { colorHex: '#4A2A05', name: 'GS2261' },
  { colorHex: '#363532', name: 'GS2874' },
  { colorHex: '#363532', name: 'GS2875' },
];

const embroideryThreadColor = [
  { colorHex: '#F0EFDB', name: 'DTL520' },
  { colorHex: '#E5DDD2', name: 'DTL100' },
  { colorHex: '#EBBEB9', name: 'DTL140' },
  { colorHex: '#AED345', name: 'DTL228' },
  { colorHex: '#E8C6DA', name: 'DTL123' },
  { colorHex: '#B7A2B5', name: 'DTL672' },
  { colorHex: '#E7DEB5', name: 'DTL5205' },
  { colorHex: '#E7D2D9', name: 'DTL101' },
  { colorHex: '#E3A6AE', name: 'DTL141' },
  { colorHex: '#99C541', name: 'DTL229' },
  { colorHex: '#DF83AA', name: 'DTL124' },
  { colorHex: '#A37FAF', name: 'DTL673' },
  { colorHex: '#F0DC79', name: 'DTL521' },
  { colorHex: '#EDD1DD', name: 'DTL102' },
  { colorHex: '#DF9083', name: 'DTL142' },
  { colorHex: '#6E9D54', name: 'DTL231' },
  { colorHex: '#D66991', name: 'DTL125' },
  { colorHex: '#8B5E9F', name: 'DTL674' },
  { colorHex: '#EDC748', name: 'DTL522' },
  { colorHex: '#E5B6C4', name: 'DTL103' },
  { colorHex: '#D97D80', name: 'DTL143' },
  { colorHex: '#4C8E50', name: 'DTL232' },
  { colorHex: '#C7337F', name: 'DTL126' },
  { colorHex: '#60306E', name: 'DTL675' },
  { colorHex: '#EEBD44', name: 'DTL523' },
  { colorHex: '#DD8699', name: 'DTL104' },
  { colorHex: '#DD8699', name: 'DTL144' },
  { colorHex: '#48935E', name: 'DTL233' },
  { colorHex: '#B42B59', name: 'DTL127' },
  { colorHex: '#572761', name: 'DTL676' },
  { colorHex: '#E79C37', name: 'DTL524' },
  { colorHex: '#DE7A8A', name: 'DTL105' },
  { colorHex: '#A1341F', name: 'DTL145' },
  { colorHex: '#d5d2cd', name: 'DTL283' },
  { colorHex: '#ad2e59', name: 'DTL128' },
  { colorHex: '#C29E88', name: 'DTL765' },
  { colorHex: '#DE822F', name: 'DTL525' },
  { colorHex: '#D16171', name: 'DTL106' },
  { colorHex: '#CABEAE', name: 'DTL810' },
  { colorHex: '#ACABB1', name: 'DTL284' },
  { colorHex: '#96365B', name: 'DTL129' },
  { colorHex: '#AC7F6C', name: 'DTL766' },
  { colorHex: '#9C7A4C', name: 'DTL783' },
  { colorHex: '#C8D9D3', name: 'DTL260' },
  { colorHex: '#BFA491', name: 'DTL811' },
  { colorHex: '#9197A3', name: 'DTL485' },
  { colorHex: '#D2ED83', name: 'DTL210' },
  { colorHex: '#967669', name: 'DTL767' },
  { colorHex: '#986026', name: 'DTL784' },
  { colorHex: '#AEDAC2', name: 'DTL261' },
  { colorHex: '#A7907E', name: 'DTL813' },
  { colorHex: '#706B70', name: 'DTL486' },
  { colorHex: '#706B70', name: 'DTL212' },
  { colorHex: '#9D4F3B', name: 'DTL768' },
  { colorHex: '#966636', name: 'DTL785' },
  { colorHex: '#B9E0C1', name: 'DTL262' },
  { colorHex: '#897A67', name: 'DTL814' },
  { colorHex: '#524E4B', name: 'DTL487' },
  { colorHex: '#BFBC5D', name: 'DTL214' },
  { colorHex: '#73402B', name: 'DTL769' },
  { colorHex: '#833F1A', name: 'DTL786' },
  { colorHex: '#85B88F', name: 'DTL263' },
  { colorHex: '#81725F', name: 'DTL815' },
  { colorHex: '#2F2A26', name: 'DTL488' },
  { colorHex: '#716C2A', name: 'DTL216' },
  { colorHex: '#A9B3CC', name: 'DTL310' },
  { colorHex: '#91ABC4', name: 'DTL370' },
  { colorHex: '#529574', name: 'DTL264' },
  { colorHex: '#93B09E', name: 'DTL201' },
  { colorHex: '#EDDF94', name: 'DTL501' },
  { colorHex: '#6F6A2B', name: 'DTL218' },
  { colorHex: '#656C7F', name: 'DTL312' },
  { colorHex: '#4191CB', name: 'DTL371' },
  { colorHex: '#347358', name: 'DTL265' },
  { colorHex: '#6FA17C', name: 'DTL202' },
  { colorHex: '#E8C959', name: 'DTL502' },
  { colorHex: '#EABAAC', name: 'DTL180' },
  { colorHex: '#776F84', name: 'DTL314' },
  { colorHex: '#3571B2', name: 'DTL372' },
  { colorHex: '#2C6248', name: 'DTL200' },
  { colorHex: '#73856D', name: 'DTL203' },
  { colorHex: '#ECC349', name: 'DTL503' },
  { colorHex: '#E08F7E', name: 'DTL182' },
  { colorHex: '#555C79', name: 'DTL316' },
  { colorHex: '#2B60AE', name: 'DTL373' },
  { colorHex: '#C6DBF0', name: 'DTL361' },
  { colorHex: '#566159', name: 'DTL204' },
  { colorHex: '#b9953d', name: 'DTL512' },
  { colorHex: '#DC7A63', name: 'DTL184' },
  { colorHex: '#2D2D4B', name: 'DTL318' },
  { colorHex: '#595E35', name: 'DTL276' },
  { colorHex: '#D03924', name: 'DTL174' },
  { colorHex: '#AB251C', name: 'DTL363' },
  { colorHex: '#BBA598', name: 'DTL741' },
  { colorHex: '#CEA672', name: 'DTL752' },
  { colorHex: '#676F5A', name: 'DTL245' },
  { colorHex: '#B3CED9', name: 'DTL383' },
  { colorHex: '#66729A', name: 'DTL384' },
  { colorHex: '#324F76', name: 'DTL385' },
  { colorHex: '#9089B3', name: 'DTL621' },
  { colorHex: '#9E86A8', name: 'DTL622' },
  { colorHex: '#7E6C96', name: 'DTL623' },
  { colorHex: '#755D91', name: 'DTL625' },
  { colorHex: '#CCD6E1', name: 'DTL331' },
  { colorHex: '#676D9D', name: 'DTL332' },
  { colorHex: '#263F90', name: 'DTL333' },
  { colorHex: '#362D64', name: 'DTL334' },
  { colorHex: '#342C52', name: 'DTL335' },
  { colorHex: '#39253F', name: 'DTL358' },
  { colorHex: '#2C212D', name: 'DTL324' },
  { colorHex: '#C3CCB4', name: 'DTL421' },
  { colorHex: '#B7ADA1', name: 'DTL422' },
  { colorHex: '#EDE49F', name: 'DTL561' },
  { colorHex: '#C1B46E', name: 'DTL562' },
  { colorHex: '#AD9438', name: 'DTL563' },
  { colorHex: '#AA9841', name: 'DTL564' },
  { colorHex: '#96865A', name: 'DTL565' },
  { colorHex: '#BACDB7', name: 'DTL251' },
  { colorHex: '#6BA3A4', name: 'DTL253' },
  { colorHex: '#2F5D52', name: 'DTL255' },
  { colorHex: '#465145', name: 'DTL257' },
  { colorHex: '#801B16', name: 'DTL190' },
  { colorHex: '#782322', name: 'DTL192' },
  { colorHex: '#5A2521', name: 'DTL194' },
  { colorHex: '#492028', name: 'DTL196' },
  { colorHex: '#3F2436', name: 'DTL198' },
  { colorHex: '#8FC2D7', name: 'DTL390' },
  { colorHex: '#3D73A2', name: 'DTL391' },
  { colorHex: '#3D73A2', name: 'DTL392' },
  { colorHex: '#2E3755', name: 'DTL393' },
  { colorHex: '#E4BF96', name: 'DTL531' },
  { colorHex: '#E29238', name: 'DTL533' },
  { colorHex: '#D97431', name: 'DTL535' },
  { colorHex: '#C7BD5C', name: 'DTL283' },
  { colorHex: '#807244', name: 'DTL285' },
  { colorHex: '#827C5C', name: 'DTL236' },
  { colorHex: '#7A6C47', name: 'DTL237' },
  { colorHex: '#6C5D3E', name: 'DTL238' },
  { colorHex: '#DFC7DD', name: 'DTL131' },
  { colorHex: '#B482A7', name: 'DTL132' },
  { colorHex: '#B283A9', name: 'DTL133' },
  { colorHex: '#7E3D75', name: 'DTL134' },
  { colorHex: '#662960', name: 'DTL135' },
  { colorHex: '#5D1A29', name: 'DTL137' },
  { colorHex: '#BDB298', name: 'DTL841' },
  { colorHex: '#AD9E89', name: 'DTL843' },
  { colorHex: '#928261', name: 'DTL844' },
  { colorHex: '#6E563E', name: 'DTL845' },
  { colorHex: '#C9A060', name: 'DTL711' },
  { colorHex: '#A75F24', name: 'DTL712' },
  { colorHex: '#7C4E2F', name: 'DTL713' },
  { colorHex: '#743723', name: 'DTL714' },
  { colorHex: '#BA2C61', name: 'DTL155' },
  { colorHex: '#DE6033', name: 'DTL1051' },
  { colorHex: '#CD412F', name: 'DTL1052' },
  { colorHex: '#BF2B1E', name: 'DTL1053' },
  { colorHex: '#D5CDCB', name: 'DTL451' },
  { colorHex: '#A09593', name: 'DTL452' },
  { colorHex: '#6C5F56', name: 'DTL453' },
  { colorHex: '#9DA6AD', name: 'DTL342' },
  { colorHex: '#52576A', name: 'DTL343' },
  { colorHex: '#4D484C', name: 'DTL344' },
  { colorHex: '#B8AE79', name: 'DTL287' },
  { colorHex: '#80763B', name: 'DTL288' },
  { colorHex: '#70662D', name: 'DTL289' },
  { colorHex: '#ADF182', name: 'DTL2' },
  { colorHex: '#EBFC7A', name: 'DTL4' },
  { colorHex: '#BF2A24', name: 'DTL9' },
  { colorHex: '#D85474', name: 'DTL11' },
  { colorHex: '#F1F876', name: 'DTL17' },
  { colorHex: '#EDCD53', name: 'DTL21' },
  { colorHex: '#E3A34C', name: 'DTL23' },
  { colorHex: '#E27432', name: 'DTL26' },
  { colorHex: '#DB5BA6', name: 'DTL27' },
  { colorHex: '#D63B7C', name: 'DTL28' },
  { colorHex: '#EDEEF3', name: 'DTL805' },
  { colorHex: '#6A4584', name: 'DTL626' },
  { colorHex: '#76393A', name: 'DTL167' },
  { colorHex: '#8A4528', name: 'DTL755' },
  { colorHex: '#4B3E2E', name: 'DTL415' },
];

const availableColor = embroideryThreadColor;
export { availableColor, embroideryThreadColor, fabricColor, wovenThread };
